export default function accordion() {
	/*eslint-disable */

	// Get all accordions
	const accordionItems = document.querySelectorAll('.accordion');

	// Loop through each accordion
	accordionItems.forEach(accordionItem => {
		// Get all accordion items
		const accordionItems = accordionItem.querySelectorAll('.accordion__content');

		// Loop through each accordion item
		accordionItems.forEach(accordionItem => {
			// Get the accordion item header
			const accordionItemHeader = accordionItem.querySelector('.accordion__heading');

			// Get the accordion item content
			const accordionItemContent = accordionItem.querySelector('.accordion__text');

			// Listen for click event
			accordionItemHeader.addEventListener('click', event => {
				// Add class to sibling element with class plus-minus
				accordionItemHeader.querySelector('.plus-minus').classList.toggle('collapsed');

				// Toggle the accordion item content
				jQuery(accordionItemContent).slideToggle();
			});
		});
	});
}