/**
 * Global imports
 */
import svg4everybody from 'svg4everybody';
import MobileMenu from './components/mobile-menu';
import testimonialsSlider from './blocks/testimonials';
import programsSlider from './blocks/programsSlider';
import accordion from './blocks/accordion';
import imageText from './blocks/imageText';

document.addEventListener('DOMContentLoaded', () => {
	svg4everybody();
	MobileMenu();
	accordion();

	if (document.querySelector('.programs-slider')) {
		programsSlider();
	}

	if (document.querySelector('.image-text__videos')) {
		imageText();
	}
});

const testimonialsSliderElement = document.querySelector('.testimonials__container');

if (testimonialsSliderElement) {
	testimonialsSlider();
}

