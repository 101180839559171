import Swiper, { Pagination } from 'swiper';
// import styles
import 'swiper/css';
import 'swiper/css/pagination';

export default function () {
	const swiper = new Swiper('.programs-slider', {
		modules      : [Pagination],
		slidesPerView: 1,
		spaceBetween : 30,
		pagination   : {
			el       : '.programs-slider .swiper-pagination',
			type     : 'bullets',
			clickable: true,
		},
		breakpoints  : {
			500: {
				slidesPerView: 2,
			},
			769: {
				spaceBetween : 30,
				slidesPerView: 3,
			},
		},
	});
}
