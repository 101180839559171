const $ = jQuery;
export default function () {
	// Get all .image-text__video buttons
	let buttons = document.querySelectorAll('.image-text__video');

	// Add event listeners to each button
	buttons.forEach((button) => {
		// Find the corresponding dialog based on the button's sibling with class 'image-text__modal'
		let dialog = button.nextElementSibling;
		let cancel = dialog.querySelector('.image-text__modal-close');

		// Add click event listener to the button
		button.addEventListener('click', (event) => {
			event.preventDefault();
			dialog.showModal();
		});

		cancel.addEventListener('click', () => {
			dialog.close();
		});

		document.addEventListener('click', ({ target }) => target === dialog && dialog.close());
	});
}
